<template>
    <uw-content title="任务类型" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button @click="$refs.dialogCreate.Open(id)" type="primary" size="mini">新的类型</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-footer="false" :show-header="false">

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    height="100%"
                    :row-config="{keyField: 'id'}"
                    :tree-config="{transform: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, reserve: true}">

                    <!-- ID -->
                    <vxe-column width="60" type="seq" title="ID"></vxe-column>

                    <!-- 类型名称 -->
                    <vxe-column min-width="200" field="name" title="类型名称" tree-node>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 类型名称 -->
                    <vxe-column min-width="120" field="working_days" title="参考工时/P"></vxe-column>

                    <vxe-column width="120" field="system" title="类型">
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.system" type="info">系统默认</el-link>
                            <el-link :underline="false" v-if="!row.system" >自定义</el-link>
                        </template>
                    </vxe-column>

                    <vxe-column width="140" field="created_at" title="创建日期"></vxe-column>
                    <vxe-column width="140" field="updated_at" title="更新日期"></vxe-column>
                    
                    <vxe-column width="95" title="操作" fixed="right" align="left">
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" class="w-margin-r-10" @click="$refs.dialogUpdate.Open(row.id)">编辑</el-link>
                            <el-link :underline="false" type="danger" @click="TableDelete(row.id)">移除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

        </uw-table>

        <!-- 组件 -->
        <projects-task-type-create ref="dialogCreate" @onChange="TableSearch" />
        <projects-task-type-update ref="dialogUpdate" @onChange="TableSearch" />
    </uw-content>
</template>

<script>

export default {
    data () {
        return {
            loading: true,

            table: [],
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 开启加载状态
            this.loading = true

            // 查询数据
            this.$http.post('/9api/projects-task-type/search-all').then(rps => {
                this.table = rps.data

                // 关闭加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$http.post('/9api/projects-task-type/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>
